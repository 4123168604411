const frenchTranslation = {
    // Nav
    "Home":"Accueil",
    "Introduction":"Introduction",
    "Services":"Services",
    "Faq":"FAQ",
    // Hero
    "HeroTitle":"Stratégies Haussières pour des Gains en Crypto",
    "HeroSubTitle":"Votre Chemin vers des Rendements Supérieurs",
    "ReadDocument":"Lire les Documents",
    // why Chose us
    "ChoseTitle":"Pourquoi Choisir Crypto Bulls ?",
    "ChoseDesc":"Choisissez Crypto Bulls pour une croissance financière et une sécurité inégalées. Avec nos stratégies avancées et notre équipe d'experts, nous nous engageons à maximiser vos rendements tout en assurant une expérience d'investissement sûre et sans faille.",
    "ChoseCardOneTitle":"Stratégies Prouvées",
    "ChoseCardOneDesc":"Nos stratégies d'investissement sont conçues pour minimiser les risques et maximiser les rendements.",
    "ChoseCardTwoTitle":"Équipe d'Experts",
    "ChoseCardTwoDesc":"Notre équipe est composée de professionnels chevronnés avec une vaste expérience en finance et en cryptomonnaie.",
    "ChoseCardThreeTitle":"Plateforme Sécurisée",
    "ChoseCardThreeDesc":"Nous priorisons votre sécurité avec un cryptage de premier ordre et des protocoles de transaction sécurisés.",
    // introduction
    "IntroTitle":"Présentation de Crypto Bulls",
    "IntroSectionOneTitle":"Qui Nous Sommes",
    "IntroSectionOneDesc":"Bienvenue chez Crypto Bulls, le sanctuaire de pointe pour les passionnés de cryptomonnaie et les investisseurs avisés. Préparez-vous à une véritable Renaissance de votre portefeuille d'investissement alors que nous réalisons une augmentation exponentielle, doublant votre capital en l'espace de deux ans. Grâce à l'alchimie de nos perspicaces connaissances du marché et de nos fortifications de sécurité imprenables, nous embarquons pour un voyage vers l'opulence et la sérénité.",
    "IntroSectionTwoTitle":"Notre Vision",
    "IntroSectionTwoDesc":"Chez Crypto Bulls, notre regard s'étend bien au-delà des horizons de la finance conventionnelle, envisageant un royaume où chaque individu détient les clés pour débloquer son destin financier. Avec un engagement inébranlable envers l'innovation et l'inclusivité, nous envisageons un avenir où la cryptomonnaie règne en maître en tant que précurseur de la prospérité mondiale, favorisant une communauté imprégnée de l'éthique de l'autonomie financière et de l'abondance.",
    "IntroSectionThreeTitle":"Notre Mission",
    "IntroSectionThreeDesc":"Chez Crypto Bulls, nous faisons écho aux aspirations de nombreuses âmes cherchant à exploiter le pouvoir transformateur de la cryptomonnaie. Au cœur de notre mission se trouve la démocratisation de l'autonomisation financière grâce à des stratégies d'investissement révolutionnaires. Nous nous engageons à fournir un sanctuaire de fiabilité et de prospérité inébranlables, permettant aux individus d'accroître leur richesse avec une sécurité et une fluidité optimales.",
    "IntroSectionFourTitle":"Notre Histoire",
    "IntroSectionFourDesc1":"Né du creuset de la sagacité financière et de la conviction fervente dans le potentiel latent de la cryptomonnaie, Crypto Bulls se dresse comme un bastion d'innovation et de prévoyance.",
    "IntroSectionFourDesc2":"Forgée par une coterie de financiers chevronnés et de fervents adeptes de la crypto, notre édifice est fortifié sur le socle de l'acuité du marché et des méthodologies de pointe.",
    "IntroSectionFourDesc3":"Avec une croyance indomptable dans les horizons illimités de la cryptomonnaie, nous nous efforçons de démanteler les barrières et de conférer la prospérité à tous.",
    // Services
    "Title1":"Plan",
    "Card1Title1": "Durée",
    "Card1Des1":"2 Ans",
    "Card1Title2": "Retour",
    "Card1Des2":"2 fois votre investissement initial",
    "Card1Title3": "Investissement minimum",
    "Card1Des3":"10,00,000 INR",
    "Tile2":"MESURES DE SÉCURITÉ",
    "Card2Title1": "Cryptage avancé",
    "Card2Title2": "Portefeuilles sécurisés",
    "Card2Title3": "Audits réguliers",
    "Tile3":"SUPPORT CLIENT",
    "Card3Title1": "Support 24/7",
    "Card3Title2": "Gestionnaires dédiés",
    "Card3Title3": "FAQ complètes",
    // HowItWorks
    "HowItWorks": "Comment ça Marche",
    "step1":"Étape 1",
    "step1Header": "DÉPOSER",
    "step1Desc":"Déposez votre INR. Vos fonds seront instantanément convertis en USDT.",
    "step2":"Étape 2",
    "step2Header": "GESTION DES INVESTISSEMENTS",
    "step2Desc":"Notre équipe d'experts investira votre USDT dans des opportunités soigneusement sélectionnées sur le marché des cryptomonnaies.",
    "step3":"Étape 3",
    "step3Header": "SURVEILLEZ VOTRE INVESTISSEMENT CROÎTRE",
    "step3Desc":"Sur deux ans, votre investissement croîtra régulièrement, doublant de valeur à la fin de la période.",
    "step4":"Étape 4",
    "step4Header":"RECEVOIR DES RETOURS",
    "step4Desc":"Après deux ans, vous pouvez retirer votre investissement avec les rendements, ou réinvestir pour continuer à faire croître votre richesse.",
    // FAQ
    "FAQ": "Foire Aux Questions",
    "FaqSubheading": "Vous avez des questions ? Nous sommes là pour vous aider.",
    "Question1":"Qu'est-ce que Crypto Bulls ?",
    "Answer1":"Crypto Bulls est une plateforme d'investissement en cryptomonnaie qui offre un service aux utilisateurs cherchant à accroître leur richesse grâce à des investissements en cryptomonnaie.",
    "Question2":"Comment fonctionne Crypto Bulls ?",
    "Answer2":"Les utilisateurs peuvent s'inscrire sur la plateforme Crypto Bulls et déposer leur INR, qui est ensuite converti en USDT (Tether). Notre équipe d'experts investit stratégiquement ces fonds dans un portefeuille diversifié d'actifs cryptographiques. Sur deux ans, l'investissement croît, offrant aux utilisateurs un retour de 2X sur leur investissement initial.",
    "Question3":"Mon investissement avec Crypto Bulls est-il sûr ?",
    "Answer3":"Oui, nous priorisons la sécurité des investissements de nos utilisateurs. Nous utilisons des techniques de cryptage avancées, des portefeuilles sécurisés et procédons à des audits de sécurité réguliers pour garantir la sécurité des fonds sur notre plateforme.",
    "Question4":"Quel est le montant minimum d'investissement ?",
    "Answer4":"Le montant minimum d'investissement pour notre service est de 1 crore INR.",
    "Question5":"Quel genre de rendements puis-je attendre avec Crypto Bulls ?",
    "Answer5":"Avec notre service, les utilisateurs peuvent s'attendre à doubler leur investissement initial sur deux ans.",
    "Question6":"Puis-je retirer mon investissement avant les deux ans ?",
    "Answer6":"Notre plan a une durée de deux ans. Bien que les retraits anticipés ne soient pas encouragés, les utilisateurs peuvent contacter notre équipe de support pour discuter de leurs options.",
    "Question7":"Comment puis-je suivre la performance de mon investissement ?",
    "Answer7":"Les utilisateurs peuvent suivre la performance de leur investissement en temps réel via leur tableau de bord de compte Crypto Bulls. Nous fournissons également des mises à jour et des rapports réguliers sur la performance de l'investissement.",
    "Question8":"Y a-t-il des frais associés à l'investissement dans Crypto Bulls ?",
    "Answer8":"Nous nous efforçons de maintenir notre structure tarifaire transparente et minimale. Cependant, les utilisateurs peuvent encourir des frais nominaux pour certaines transactions ou services. Veuillez consulter notre grille tarifaire pour plus de détails.",
    "Question9":"Crypto Bulls est-il régulé ?",
    "Answer9":"Bien que les investissements en cryptomonnaie ne soient pas régulés par les autorités financières traditionnelles, Crypto Bulls adhère aux meilleures pratiques de l'industrie et se conforme aux exigences légales pertinentes.",
    "Question10":"Que se passe-t-il avec mon investissement en cas de fluctuations du marché ?",
    "Answer10":"Notre équipe utilise des stratégies sophistiquées pour atténuer les risques et optimiser les rendements. Bien que les fluctuations du marché soient inévitables, notre objectif est de fournir aux utilisateurs une croissance d'investissement constante et rentable à long terme.",
    // partners
    "OurPartners": "Nos Principaux Partenaires",
    "Footer": "Droits d'auteur © 2024 Crypto Bulls. Tous droits réservés."
}

export default frenchTranslation