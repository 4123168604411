import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslation from "./english-translation";
import spanishTranslation from "./spanish-translation";
import frenchTranslation from "./french-translation";

// Import translations
const resources = {
	en: {
		translation: englishTranslation,
	},
	es: {
		translation: spanishTranslation
	},
	fr: {
		translation: frenchTranslation
	},
};

i18n.use(initReactI18next).init({
		resources,
		lng: "en",
		keySeparator: false,
		interpolation: {
			escapeValue: false,
		},
});

export default i18n;
