import React from 'react'
import { useTranslation } from 'react-i18next';

const LeftBox = () => {
    const { t } = useTranslation();
    return (
        <div className='max-w-[327px] w-full h-full sm:h-[311px] text-[#FFFFFF] border-[1px] border-white rounded-xl'>
            <div className='w-full justify-center bg-[#0396A699] rounded-t-xl h-fit border-b-[2px] p-2 items-center flex'>
                <p className='text-center  sm:text-2xl font-[600]'>{t("Title1")}</p>
            </div>
            <div className='flex font-monst p-5 w-full justify-center text-center items-center flex-col gap-4'>
                <div className='flex flex-col'>
                    <p className='text-[#FFFFFFA3] text-lg sm:text-[18px] font-[400]'>{t("Card1Title1")}</p>
                    <p className='sm:text-[18px] text-[14px] font-[500]'>{t("Card1Des1")}</p>
                </div>
                <div className='flex flex-col'>
                    <p className='text-[#FFFFFFA3] text-lg sm:text-[18px] font-[400]'>{t("Card1Title2")}</p>
                    <p className='sm:text-[18px] text-[14px] font-[500]'>{t("Card1Des2")}</p>
                </div>
                <div className='flex flex-col'>
                    <p className='text-[#FFFFFFA3] text-lg sm:text-[18px] font-[400]'>{t("Card1Title3")}</p>
                    <p className='sm:text-[18px] text-[14px] font-[500]'>{t("Card1Des3")}</p>
                </div>
            </div>
        </div>
    )
}

export default LeftBox
