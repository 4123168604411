const englishTranslation = {
    // Nav
    "Home":"Home",
    "Introduction":"Introduction",
    "Faq":"Faq",
    // Hero
    "HeroTitle":"Bullish Strategies for Crypto Gains",
    "HeroSubTitle":"Your Path to Higher Yields",
    "ReadDocument":"Read Documents",
    // why Chose us
    "ChoseTitle":"Why You Choose Crypto Bulls ?",
    "ChoseDesc":"Choose Crypto Bulls for unparalleled financial growth and security. With our advanced strategies and expert team, we're dedicated to maximizing your returns while ensuring a safe and seamless investment experience.",
    "ChoseCardOneTitle":"Proven Strategies",
    "ChoseCardOneDesc":"Our investment strategies are designed to minimize risk and maximize returns.",
    "ChoseCardTwoTitle":"Expert Team",
    "ChoseCardTwoDesc":"Our team consists of seasoned professionals with extensive experience in finance and cryptocurrency.",
    "ChoseCardThreeTitle":"Secure Platform",
    "ChoseCardThreeDesc":"We prioritize your security with top-notch encryption and secure transaction protocols.",
    // introduction
    "IntroTitle":"Introducing Crypto Bulls",
    "IntroSectionOneTitle":"Who We Are",
    "IntroSectionOneDesc":"Welcome to Crypto Bulls, the forefront sanctuary for aficionados of the crypto-verse and discerning investors alike. Brace yourself for a veritable Renaissance in your investment portfolio as we conduct an exponential crescendo, doubling your capital within a mere biennium. Through the alchemy of our perspicacious market insights and impregnable fortifications of security, we embark upon a voyage toward opulence and serenity.",
    "IntroSectionTwoTitle":"Our Vision",
    "IntroSectionTwoDesc":"At Crypto Bulls, our gaze extends far beyond the horizons of conventional finance, envisioning a realm where each individual wields the keys to unlock their financial destiny. With an unwavering commitment to innovation and inclusivity, we envisage a future where cryptocurrency reigns supreme as the harbinger of global prosperity, fostering a community imbued with the ethos of financial autonomy and abundance.",
    "IntroSectionThreeTitle":"Our Mission",
    "IntroSectionThreeDesc":"At Crypto Bulls, we echo the yearnings of countless souls yearning to harness the transformative power of cryptocurrency. At the heart of our mission lies the democratization of financial empowerment through groundbreaking investment strategies. We commit to providing a sanctuary of unwavering reliability and prosperity, enabling individuals to enhance their wealth with utmost security and fluidity.",
    "IntroSectionFourTitle":"Our Story",
    "IntroSectionFourDesc1":"Born from the crucible of financial sagacity and fervent conviction in the latent potential of cryptocurrency, Crypto Bulls stands as a bastion of innovation and prescience.",
    "IntroSectionFourDesc2":"Forged by a coterie of seasoned financiers and fervid crypto savants, our edifice is fortified upon the bedrock of astute market acumen and cutting-edge methodologies.",
    "IntroSectionFourDesc3":"With an indomitable belief in the limitless vistas of cryptocurrency, we endeavor to dismantle barriers and bestow prosperity upon all.",
    // Services
    "Services": "Services",
    "Title1":"Plan",
    "Card1Title1": "Duration",
    "Card1Des1":"2 Years",
    "Card1Title2": "Return",
    "Card1Des2":"2x of your initial investment",
    "Card1Title3": "Minimum investment",
    "Card1Des3":"10,00,000 INR",
    "Tile2":"SECURITY MEASURES",
    "Card2Title1": "Advance encryption",
    "Card2Title2": "Secure wallets",
    "Card2Title3": "Regular Audits",
    "Tile3":"CUSTOMER SUPPORT",
    "Card3Title1": "24/7 support",
    "Card3Title2": "Dedicated managers",
    "Card3Title3": "Comprehensive FAQs",
    // HowItWorks
    "HowItWorks": "How It Works",
    "step1":"Step 1",
    "step1Header": "DEPOSIT",
    "step1Desc":"Deposit your INR. Your funds will be instantly converted to USDT.",
    "step2":"Step 2",
    "step2Header": "INVESTMENT MANAGEMENT",
    "step2Desc":"Our expert team will invest your USDT in carefully selected opportunities within the cryptocurrency market.",
    "step3":"Step 3",
    "step3Header": "WATCH YOUR INVESTMENT GROW",
    "step3Desc":"Over two years, your investment will grow steadily, doubling in value by the end of the term.",
    "step4":"Step 4",
    "step4Header":"Recieve Returns",
    "step4Desc":"After two years, you can withdraw your investment along with the returns, or reinvest to continue growing your wealt",
    // FAQ
    "FAQ": "Frequently Asked Questions",
    "FaqSubheading": "Do have any kind of questions? We're here to help.",
    "Question1":"What is Crypto Bulls?",
    "Answer1":"Crypto Bulls is a cryptocurrency investment platform that offers a service for users looking to grow their wealth through cryptocurrency investments.",
    "Question2":"How does Crypto Bulls work?",
    "Answer2":"Users can sign up on the Crypto Bulls platform, and deposit their INR, which is then converted to USDT (Tether). Our expert team strategically invests these funds across a diversified portfolio of crypto assets. Over two years, the investment grows, providing users with a 2X return on their initial investment.",
    "Question3":"Is my investment with Crypto Bulls safe?",
    "Answer3":" Yes, we prioritize the security of our users' investments. We employ advanced encryption techniques, secure wallets, and undergo regular security audits to ensure the safety of funds on our platform.",
    "Question4":"What is the minimum investment amount?",
    "Answer4":"The minimum investment amount for our service is 1Cr.",
    "Question5":"What kind of returns can I expect with Crypto Bulls?",
    "Answer5":"With our service, users can expect to double their initial investment over two years.",
    "Question6":"Can I withdraw my investment before the two years?",
    "Answer6":"Our plan has a duration of two years. While early withdrawals are not encouraged, users may contact our support team to discuss their options.",
    "Question7":"How do I monitor the performance of my investment?",
    "Answer7":"Users can track the performance of their investment in real-time through their Crypto Bulls account dashboard. We also provide regular updates and reports on investment performance.",
    "Question8":"Are there any fees associated with investing in Crypto Bulls?",
    "Answer8":"We strive to keep our fee structure transparent and minimal. However, users may incur nominal fees for certain transactions or services. Please refer to our fee schedule for more details.",
    "Question9":"Are Crypto Bulls regulated?",
    "Answer9":"While cryptocurrency investments are not regulated by traditional financial authorities, Crypto Bulls adheres to industry best practices and complies with relevant legal requirements.",
    "Question10":"What happezns to my investment if there are market fluctuations?",
    "Answer10":"Our team employs sophisticated strategies to mitigate risks and optimize returns. While market fluctuations are inevitable, our goal is to provide users with consistent and profitable investment growth over the long term.",
    // partners
    "OurPartners": "Our Top Partners",
    "Footer": "Copyright © 2024Crypto Bulls.All rights reserved."

}

export default englishTranslation;