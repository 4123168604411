import React, { forwardRef } from 'react';
import Left from './Left';
import Right from './Right';
import Text from '../../Services/Text';
import { useTranslation } from 'react-i18next';

const IndexIntro = forwardRef(() => {
    const { t } = useTranslation();
    const x = {
        name: t('IntroSectionFourTitle'),
        details: t('IntroSectionFourDesc1'),
        detalis2: t('IntroSectionFourDesc2'),
        details3: t('IntroSectionFourDesc3'),
        className: 'text-start font-[400] text-[10px] font-monst flex flex-wrap'
    };

    return (
        <section id="intro" className='bg-gradient-to-tr p-5 to-[#013A40] from-[#0396A6]' style={{ paddingTop: '10vh' }}>
            <div className='lg:mt-16 flex flex-col-reverse justify-center lg:flex-row gap-5 w-full lg:justify-between px-[2%] sm:px-[13%]'>
                <div className='flex lg:justify-start gap-3 justify-center items-center flex-col'>
                    <Left />
                    <div className='lg:hidden lg:mt-0 block'>
                        <Text x={x} />
                    </div>
                </div>
                <Right />
            </div>
        </section>
    );
});

export default IndexIntro;
