import React from 'react'
import { useTranslation } from 'react-i18next';

const LowerTextProps = () => {
    const { t } = useTranslation();
    const item = [
        {
            name: t("IntroSectionOneTitle"),
            details: t("IntroSectionOneDesc"),
            details2: '',
            details3: '',
            className: 'flex lg:text-start text-center w-full lg:w-[400px] xl:w-[550px] flex-col'
        },
        {
            name: t("IntroSectionTwoTitle"),
            details: t("IntroSectionTwoDesc"),
            details2: '',
            details3: '',
            className: 'flex lg:w-[400px] w-full flex-col xl:w-[550px] text-center'
        },
        {
            name: t("IntroSectionThreeTitle"),
            details: t("IntroSectionThreeDesc"),
            details2: '',
            details3: '',
            className: 'flex lg:w-[400px] w-full flex-col xl:w-[550px] text-center'

        }
    ]
    return (
        <>
            <div className='w-full text-white justify-center flex'>
                <div className='flex font-monst flex-col gap-4'>
                    {
                        item && item.map((x, ind) => {
                            return (
                                <div key={ind} className='flex mt-9 lg:mt-2 gap-3 flex-col'>
                                    <div className='flex gap-3 text-start justify-start items-center'>
                                        <div className='w-[15px] h-[15px] bg-white rounded-full'></div>
                                        <p className='sm:text-2xl text-lg font-[600] text-white'>{x.name}</p>
                                    </div>
                                    <div className={`font-monst ${x.className}`}>
                                        <p className='text-start font-[400] text-[14px] flex flex-wrap'>
                                            {x.details}
                                        </p>
                                        <p className='text-start font-[400] text-[14px] flex flex-wrap'>
                                            {x.details2}
                                        </p>
                                        <p className='text-start font-[400] text-[14px] flex flex-wrap'>
                                            {x.details4}
                                        </p>
                                        <p className='text-start font-[400] text-[14px] flex flex-wrap'>
                                            {x.details3}
                                        </p>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default LowerTextProps
