import React, { useState } from 'react';
import '../../src/index.css';
import { useTranslation } from 'react-i18next';

const QuestionProps = () => {
    const { t } = useTranslation();
    const ques = [
        {
            number: '1',
            ques: t("Question1"),
            answer: t("Answer1")
        },
        {
            number: '2',
            ques: t("Question2"),
            answer: t("Answer2")
        },
        {
            number: '3',
            ques: t("Question3"),
            answer: t("Answer3")
        },
        {
            number: '4',
            ques: t("Question4"),
            answer: t("Answer4")
        },
        {
            number: '5',
            ques: t("Question5"),
            answer: t("Answer5")
        },
        {
            number: '6',
            ques: t("Question6"),
            answer: t("Answer6")
        },
        {
            number: '7',
            ques: t("Question7"),
            answer: t("Answer7")
        },
        {
            number: '8',
            ques: t("Question8"),
            answer: t("Answer8")
        },
        {
            number: '9',
            ques: t("Question9"),
            answer: t("Answer9")
        },
        {
            number: '10',
            ques: t("Question10"),
            answer: t("Answer10")
        },
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAnswer = (index) => {
        setActiveIndex(prevIndex => (prevIndex === index ? null : index));
    };

    return (
        <div className='flex flex-col gap-4 lg:pt-[8vh] font-monst justify-center items-center'>
            {ques.map((x, ind) => (
                <div
                    key={ind}
                    onClick={() => toggleAnswer(ind)}
                    className='lg:w-[480px] xl:w-[560px] justify-center w-full items-center h-full md:p-2 text-white rounded-xl bg-[#0F101E] border-[1px] border-black cursor-pointer'
                >
                    <div className='w-full h-full justify-center items-center flex flex-col p-2'>
                        <div className='flex h-[100%] w-full justify-between items-center gap-3'>
                            <div className='flex w-full flex-row gap-5 items-center'>
                                <div>
                                    <div className='gradient-border w-8 h-8 sm:w-[40px] sm:h-[40px] flex items-center justify-center'>
                                        <p className='text-white text-sm md:text-lg font-bold'>{x.number}</p>
                                    </div>
                                </div>
                                <div className='flex justify-center h-full flex-col'>
                                    <p className='font-[500] text-[10px] sm:text-[18px]'>{x.ques}</p>
                                    <div className='md:block hidden'>
                                        {activeIndex === ind && (
                                            <div className='transition-all w-full qa-answer'>
                                                <p className='text-[14px] text-[#BDBDBD] font-[400]'>{x.answer}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='flex h-fit px-2'>
                                {activeIndex === ind ? (
                                    <p className='text-white font-[700] text-[16px]'>-</p>
                                ) : (
                                    <p className='text-white font-[700] text-[16px]'>+</p>
                                )}
                            </div>
                        </div>
                        <div className='md:hidden block'>
                            {activeIndex === ind && (
                                <div className='transition-all p-2'>
                                    <p className='text-[10px] text-[#BDBDBD] font-[400]'>{x.answer}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default QuestionProps;