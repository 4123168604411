import React from 'react';
import darkhole from '../assets/Darkholeimg.png';
import innerDarkhole from '../assets/InnerDarkhole.png';
import LongRod from '../assets/LongRod.png';
import { useTranslation } from 'react-i18next';

const SliderProps = () => {
    const { t } = useTranslation();

    const item = [
        {
            steps: t("step1"),
            header: t("step1Header"),
            details: t("step1Desc"),
        },
        {
            steps: t("step2"),
            header: t("step2Header"),
            details: t("step2Desc"),
        },
        {
            steps: t("step3"),
            header: t("step3Header"),
            details: t("step3Desc"),
        },
        {
            steps: t("step4"),
            header: t("step4Header"),
            details: t("step4Desc"),

        }
    ]
    return (
        <>
            {
                item && item.map((x, ind) => {
                    return (
                        <div key={ind} className='w-[280px] md:w-[329px] min-h-[275px] h-full rounded-xl mt-16 md:mt-24 bg-[#010314]'>
                            <div className='flex gap-3 justify-start items-start w-full pt-16 p-8'>
                                <div className='relative justify-center text-center items-center flex'>
                                    <img src={darkhole} className='max-w-[30px] max-h-[30px] rounded-full' alt="" />
                                    <img src={innerDarkhole} className='flex absolute' alt="" />
                                    <img src={LongRod} className='flex absolute bottom-7' alt="" />
                                    <p className='flex absolute bottom-32 text-white text-2xl justify-center font-[600] w-[100px]'>{x.steps}</p>
                                </div>
                                <div className=' text-white flex flex-col gap-2'>
                                    <p className='text-start text-xl font-[500] font-expletus text-[20px]'>{x.header}</p>
                                    <p className='font-[400] text-start text-[14px] font-monst'>{x.details}</p>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    );
}

export default SliderProps;
