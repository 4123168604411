const spanishTranslation = {
    // Nav
    "Home":"Inicio",
    "Introduction":"Introducción",
    "Services":"Servicios",
    "Faq":"FAQ",
    // Hero
    "HeroTitle":"Estrategias Alcistas para Ganancias en Criptomonedas",
    "HeroSubTitle":"Tu Camino hacia Rendimientos Superiores",
    "ReadDocument":"Leer Documentos",
    // why Chose us
    "ChoseTitle":"¿Por Qué Elegir Crypto Bulls?",
    "ChoseDesc":"Elige Crypto Bulls para un crecimiento financiero y seguridad inigualables. Con nuestras estrategias avanzadas y nuestro equipo de expertos, nos dedicamos a maximizar tus rendimientos mientras aseguramos una experiencia de inversión segura y sin complicaciones.",
    "ChoseCardOneTitle":"Estrategias Probadas",
    "ChoseCardOneDesc":"Nuestras estrategias de inversión están diseñadas para minimizar riesgos y maximizar rendimientos.",
    "ChoseCardTwoTitle":"Equipo de Expertos",
    "ChoseCardTwoDesc":"Nuestro equipo está compuesto por profesionales experimentados con amplia experiencia en finanzas y criptomonedas.",
    "ChoseCardThreeTitle":"Plataforma Segura",
    "ChoseCardThreeDesc":"Priorizamos tu seguridad con cifrado de alta calidad y protocolos de transacción seguros.",
    // introduction
    "IntroTitle":"Presentando Crypto Bulls",
    "IntroSectionOneTitle":"Quiénes Somos",
    "IntroSectionOneDesc":"Bienvenido a Crypto Bulls, el refugio de vanguardia para los aficionados al criptoverso y los inversores exigentes. Prepárate para un verdadero Renacimiento en tu cartera de inversiones mientras realizamos un aumento exponencial, duplicando tu capital en solo dos años. A través de la alquimia de nuestras perspicaces percepciones del mercado y nuestras inexpugnables fortificaciones de seguridad, nos embarcamos en un viaje hacia la opulencia y la serenidad.",
    "IntroSectionTwoTitle":"Nuestra Visión",
    "IntroSectionTwoDesc":"En Crypto Bulls, nuestra mirada se extiende mucho más allá de los horizontes de las finanzas convencionales, imaginando un reino donde cada individuo posea las llaves para desbloquear su destino financiero. Con un compromiso inquebrantable con la innovación y la inclusividad, imaginamos un futuro donde las criptomonedas reinen supremas como el precursor de la prosperidad global, fomentando una comunidad impregnada con la ética de la autonomía financiera y la abundancia.",
    "IntroSectionThreeTitle":"Nuestra Misión",
    "IntroSectionThreeDesc":"En Crypto Bulls, hacemos eco de los anhelos de innumerables almas que buscan aprovechar el poder transformador de las criptomonedas. En el corazón de nuestra misión se encuentra la democratización del empoderamiento financiero a través de estrategias de inversión innovadoras. Nos comprometemos a proporcionar un refugio de fiabilidad y prosperidad inquebrantables, permitiendo a los individuos aumentar su riqueza con la máxima seguridad y fluidez.",
    "IntroSectionFourTitle":"Nuestra Historia",
    "IntroSectionFourDesc1":"Nacido del crisol de la sagacidad financiera y la ferviente convicción en el potencial latente de las criptomonedas, Crypto Bulls se erige como un bastión de innovación y previsión.",
    "IntroSectionFourDesc2":"Forjado por una camarilla de financieros experimentados y fervientes entusiastas de las criptomonedas, nuestro edificio está fortificado sobre la base del conocimiento del mercado y las metodologías de vanguardia.",
    "IntroSectionFourDesc3":"Con una creencia indomable en los horizontes ilimitados de las criptomonedas, nos esforzamos por desmantelar barreras y otorgar prosperidad a todos.",
    // Services
    "Title1":"Plan",
    "Card1Title1": "Duración",
    "Card1Des1":"2 Años",
    "Card1Title2": "Retorno",
    "Card1Des2":"2 veces tu inversión inicial",
    "Card1Title3": "Inversión mínima",
    "Card1Des3":"10,00,000 INR",
    "Tile2":"MEDIDAS DE SEGURIDAD",
    "Card2Title1": "Cifrado avanzado",
    "Card2Title2": "Carteras seguras",
    "Card2Title3": "Auditorías regulares",
    "Tile3":"SOPORTE AL CLIENTE",
    "Card3Title1": "Soporte 24/7",
    "Card3Title2": "Gerentes dedicados",
    "Card3Title3": "FAQ completas",
    // HowItWorks
    "HowItWorks": "Cómo Funciona",
    "step1":"Paso 1",
    "step1Header": "DÉPOSER",
    "step1Desc":"Deposite su INR. Sus fondos se convertirán instantáneamente en USDT.",
    "step2":"Paso 2",
    "step2Header": "GESTION DES INVESTISSEMENTS",
    "step2Desc":"Nuestro equipo de expertos invertirá su USDT en oportunidades cuidadosamente seleccionadas en el mercado de criptomonedas.",
    "step3":"Paso 3",
    "step3Header": "SURVEILLEZ VOTRE INVESTISSEMENT CROÎTRE",
    "step3Desc":"Durante dos años, su inversión crecerá constantemente, duplicando su valor al final del período.",
    "step4":"Paso 4",
    "step4Header":"RECEVOIR DES RETOURS",
    "step4Desc":"Después de dos años, puede retirar su inversión junto con los rendimientos, o reinvertir para seguir aumentando su riqueza.",
    // FAQ
    "FAQ": "Preguntas Frecuentes",
    "FaqSubheading": "¿Tienes alguna pregunta? Estamos aquí para ayudar.",
    "Question1":"¿Qué es Crypto Bulls?",
    "Answer1":"Crypto Bulls es una plataforma de inversión en criptomonedas que ofrece un servicio para usuarios que buscan aumentar su riqueza a través de inversiones en criptomonedas.",
    "Question2":"¿Cómo funciona Crypto Bulls?",
    "Answer2":"Los usuarios pueden registrarse en la plataforma Crypto Bulls y depositar su INR, que luego se convierte en USDT (Tether). Nuestro equipo de expertos invierte estratégicamente estos fondos en una cartera diversificada de activos criptográficos. Durante dos años, la inversión crece, proporcionando a los usuarios un retorno de 2X sobre su inversión inicial.",
    "Question3":"¿Es seguro mi inversión con Crypto Bulls?",
    "Answer3":"Sí, priorizamos la seguridad de las inversiones de nuestros usuarios. Utilizamos técnicas avanzadas de cifrado, carteras seguras y realizamos auditorías de seguridad regulares para garantizar la seguridad de los fondos en nuestra plataforma.",
    "Question4":"¿Cuál es el monto mínimo de inversión?",
    "Answer4":"El monto mínimo de inversión para nuestro servicio es de 1 crore INR.",
    "Question5":"¿Qué tipo de rendimientos puedo esperar con Crypto Bulls?",
    "Answer5":"Con nuestro servicio, los usuarios pueden esperar duplicar su inversión inicial en dos años.",
    "Question6":"¿Puedo retirar mi inversión antes de los dos años?",
    "Answer6":"Nuestro plan tiene una duración de dos años. Aunque no se recomienda realizar retiros anticipados, los usuarios pueden contactar a nuestro equipo de soporte para discutir sus opciones.",
    "Question7":"¿Cómo puedo monitorear el rendimiento de mi inversión?",
    "Answer7":"Los usuarios pueden seguir el rendimiento de su inversión en tiempo real a través de su panel de cuenta de Crypto Bulls. También proporcionamos actualizaciones y informes regulares sobre el rendimiento de la inversión.",
    "Question8":"¿Hay tarifas asociadas con invertir en Crypto Bulls?",
    "Answer8":"Nos esforzamos por mantener nuestra estructura de tarifas transparente y mínima. Sin embargo, los usuarios pueden incurrir en tarifas nominales por ciertas transacciones o servicios. Por favor, consulte nuestra tabla de tarifas para más detalles.",
    "Question9":"¿Crypto Bulls está regulado?",
    "Answer9":"Aunque las inversiones en criptomonedas no están reguladas por las autoridades financieras tradicionales, Crypto Bulls se adhiere a las mejores prácticas de la industria y cumple con los requisitos legales relevantes.",
    "Question10":"¿Qué pasa con mi inversión si hay fluctuaciones en el mercado?",
    "Answer10":"Nuestro equipo emplea estrategias sofisticadas para mitigar los riesgos y optimizar los rendimientos. Aunque las fluctuaciones del mercado son inevitables, nuestro objetivo es proporcionar a los usuarios un crecimiento de inversión constante y rentable a largo plazo.",
    // partners
    "OurPartners": "Nuestros Principales Socios",
    "Footer": "Derechos de autor © 2024 Crypto Bulls. Todos los derechos reservados."
}

export default spanishTranslation